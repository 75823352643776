// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import BSAShortHeader from '../../components/BestSchoolsAwards/Header/ShortHeader'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const BSATerms = () => {
  return (
    <>
      <Helmet>
        <title>
          FAQs | Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <BSAShortHeader />
      <BSAContent>
        <div
          className={`${ContentStyles.FAQStyle} ${ContentStyles.NarrowContent}`}
        >
          <h1>FAQs</h1>
          <p>
            <strong>How do the Awards work?</strong>
          </p>
          <p>
            The Awards entries open in early January 2024 across 13 categories.
            Any Independent school in the United Kingdom or the Channel Islands
            may put forward one school entry per category. The entry deadline
            has now{' '}
            <span style={{ color: 'rgb(231, 44, 94)' }}>been extended</span>{' '}
            meaning that entries close at{' '}
            <span style={{ fontWeight: 'bold' }}>3pm, 23 April 2024</span>.
          </p>
          <p>
            On 20th June 2024, five Highly Commended and five Finalist Schools
            per category will be announced and these schools will enter a second
            round of judging.
          </p>
          <p>
            On 27th June 2024 the Winners of each category will be announced.
          </p>

          <p>
            <strong>Who can enter the Awards?</strong>
          </p>
          <p>
            All independent fee-paying schools or fee-paying boarding schools in
            the United Kingdom or the Channel Islands are eligible to apply for
            the Awards.
          </p>

          <p>
            <strong>Can I enter more than one Award category?</strong>
          </p>
          <p>
            Yes. You can enter as many Awards categories as you like using a
            separate Entry form.
          </p>

          <p>
            <strong>How will the Awards be judged?</strong>
          </p>
          <p>
            Our expert panel of judges are leaders in their fields. The judges
            will take on specific categories based on their experience and
            particular interests. There are several categories that are split
            into separate Senior School and Prep School submissions, based on
            fairness of judging like-for-like – in these instances there will be
            winners in both Senior and Prep school categories. Simply select the
            most appropriate button for your submission. If eligible, a school
            can enter both Prep and Senior levels and each entry will need to be
            submitted on a different entry form and paid for separately (unless
            a school is using their ‘one free entry’ credit for one of the
            entries.) The judges will select the Highly Commended schools,
            Finalists and Winners. Their decision will be final, and no
            correspondence will be entered into.
          </p>

          <p>
            <strong>When will the winners be announced?</strong>
          </p>
          <p>
            <ul>
              <li>
                Highly Commended & Finalists schools will be announced on 20th
                June 2024
              </li>
              <li>Winners will be announced on 27th June 2024</li>
              <li>No correspondence will be entered into.</li>
            </ul>
          </p>

          <p>
            <strong>
              What sort of evidence do I need to provide to support an
              application and how should I submit it?
            </strong>
          </p>
          <p>
            Entries are asked to complete the online Entry Form and upload it as
            a pdf onto the Entry Submission page of the website. Supporting
            evidence to be included in the form could be (but not limited to):
            photos, video content, evaluations, testimonials, newspaper cuttings
            and inspection reports, etc. Any additional supplementary
            information may be sent by post to: Muddy Stilettos Best Schools
            Awards, Manor Courtyard, Aston Sandford, Buckinghamshire HP17 8JB.
          </p>

          <p>
            <strong>Will there be publicity about the winners?</strong>
          </p>
          <p>
            Yes. Muddy Stilettos will promote the stories of the winning schools
            across our website and social platforms to its 3.8 million users, to
            celebrate the successes of the independent school sector and their
            impact on the wider community. We will also send out a press release
            to media outlets notifying them of the winners. Schools will be
            asked to provide permission to use their Entry Form application
            information as well as publicity images when they submit their
            entries. If you do not want us to use your stories or imagery you
            must inform us at the time of submitting your applications.
          </p>

          <p>
            <strong>How much does it cost to enter?</strong>
          </p>
          <p>
            The first category submission of the Muddy Stilettos Best Schools
            Awards is FREE to enter for each school, and thereafter, each
            further submission is charged at £65 per category.
          </p>
          <p>
            <ul>
              <li>First category entry: Free</li>
              <li>All further category entries: £65 each</li>
              <li>
                There is one free entry per whole school – schools with, for
                example, Pre-Prep, Prep and Senior departments are therefore
                entitled to a single free entry, not three.
              </li>
              <li>
                Entries must be paid at time of submission before 3pm, 23 April
                2024.
              </li>
            </ul>
          </p>

          <p>
            <strong>What do I win?</strong>
          </p>
          <p>
            We want to support, promote and celebrate the best independent
            schools in the United Kingdom and the Channel Islands. The Muddy
            Stilettos Best Schools Awards 2024 winners will receive:
          </p>
          <p>
            <ul>
              <li>A Muddy Stilettos Best Schools Award Plaque</li>
              <li>
                A Muddy Stilettos Best Schools Awards website digital rosette
                for all winners in recognition of their success
              </li>
              <li>
                Awards-related publicity on Muddy Stilettos for all winners
              </li>
            </ul>
          </p>
          <p>
            <strong>
              Can I get more information on the awards and advice on how to
              apply?
            </strong>
          </p>
          <p>
            Yes. If you have any queries please email us at&nbsp;
            <a
              href="mailto:hq@muddystilettos.co.uk"
              style={{ color: '#d41b52' }}
            >
              hq@muddystilettos.co.uk
            </a>
          </p>
        </div>
      </BSAContent>
      <BSAFooter />
    </>
  )
}

export default BSATerms
